// change the theme

$primary: #67b08c;
$success: #066c37;
$danger: #f06650;
$body-color: #54412f;

$theme-colors: ("primary": $primary,
  "danger": $danger);

$link-color: #ba54f5 !default;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.requerido:after,
.required:after {
  content: '*';
  color: $danger;
  vertical-align: bottom;
  margin-left: 2px;
  line-height: 14px;
}

[aria-invalid="true"] {
  color: $danger;
}

.btn-primary {
  color: #fff;
}

.btn-primary:hover,
.btn-primary:visited,
.btn-primary:focus,
.btn-primary:active {
  color: $body-color;
}