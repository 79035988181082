.card {
    width: 350px;
}

.precoOriginal {
    text-decoration: line-through;
    font-size: 16px;
}

.precoFinal {
    font-size: 24px;
}

.precoInfoContainer {
    line-height: 1;
}

.percentualDesconto {
    position: absolute;
    top: 0px;
    right: 0px;
    color: white;
    padding: 5px;
    border-radius: 0px 4px 0px 4px;
}

.precoInfoContainer {
    display: block;
}

.carousel.carousel-slider {
    height: 348px;

}

.vitrine-container .carousel-root {
    width: 348px;
}

.vitrine-container .img-container{
    width: 348px;
    overflow: hidden;
}

.vitrine-container .img-container .img{
    width: 348px;
    height: 348px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}